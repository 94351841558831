import styled from 'styled-components';
import { media } from '../../../utils';

export const GridTest2 = styled.div`
  .container {
    display: grid;
    ${'' /* grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr; */}
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      'pierwszy drugi trzeci'
      'wyzszy czwarty piaty'
      'wyzszy szósty siódmy'
      'wyzszy osmy dziewiaty';

      ${media.tabletViewportWidth`
    display: grid;
    align-items: center;
    grid-template-rows: 8fr;
    gap: 0rem 1.2rem;
    grid-template-columns: repeat(2, 340px);
  `}

  ${media.tabletLandscapeViewportWidth`
    gap: 1.2rem 1.2rem;
    grid-template-columns: repeat(2, 300px);
  `}

  ${media.desktopViewportWidth`
    grid-template-columns: repeat(2, 380px);
  `}
  }

  .drugi {
    grid-area: drugi;
  }

  .trzeci {
    grid-area: trzeci;
  }

  .pierwszy {
    grid-area: pierwszy;
  }

  .czwarty {
    grid-area: czwarty;
  }

  .piaty {
    grid-area: piaty;
  }

  .szósty {
    grid-area: szósty;
  }

  .siódmy {
    grid-area: siódmy;
  }

  .wyzszy {
    grid-area: wyzszy;
  }

  .osmy {
    grid-area: osmy;
  }

  .dziewiaty {
    grid-area: dziewiaty;
  }

  ${media.tabletViewportWidth`
    display: grid;
    align-items: center;
    grid-template-rows: 8fr;
    gap: 0rem 1.2rem;
    grid-template-columns: repeat(2, 340px);
  `}

  ${media.tabletLandscapeViewportWidth`
    gap: 1.2rem 1.2rem;
    grid-template-columns: repeat(2, 300px);
  `}

  ${media.desktopViewportWidth`
    grid-template-columns: repeat(2, 380px);
  `}
`;

export const GridTest = styled.div`
  .container {
    display: grid;
    ${'' /* grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr; */}
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      'pierwszy drugi trzeci'
      'wyzszy czwarty piaty'
      'wyzszy szósty siódmy'
      'wyzszy osmy dziewiaty';

      ${media.tabletViewportWidth`
    display: grid;
    align-items: center;
    grid-template-rows: 8fr;
    gap: 0rem 1.2rem;
    grid-template-columns: repeat(2, 340px);
  `}

  ${media.tabletLandscapeViewportWidth`
    gap: 1.2rem 1.2rem;
    grid-template-columns: repeat(2, 300px);
  `}

  ${media.desktopViewportWidth`
    grid-template-columns: repeat(2, 380px);
  `}
  }

  .drugi {
    grid-area: drugi;
  }

  .trzeci {
    grid-area: trzeci;
  }

  .pierwszy {
    grid-area: pierwszy;
  }

  .czwarty {
    grid-area: czwarty;
  }

  .piaty {
    grid-area: piaty;
  }

  .szósty {
    grid-area: szósty;
  }

  .siódmy {
    grid-area: siódmy;
  }

  .wyzszy {
    grid-area: wyzszy;
  }

  .osmy {
    grid-area: osmy;
  }

  .dziewiaty {
    grid-area: dziewiaty;
  }

  ${media.tabletViewportWidth`
    display: grid;
    align-items: center;
    grid-template-rows: 8fr;
    gap: 0rem 1.2rem;
    grid-template-columns: repeat(2, 340px);
  `}

  ${media.tabletLandscapeViewportWidth`
    gap: 1.2rem 1.2rem;
    grid-template-columns: repeat(2, 300px);

    .is-last {
      width: 780px;

     div {
        width: 380px!important;
        margin: 0 auto;
      }
    }
  `}

  ${media.desktopViewportWidth`
    grid-template-columns: repeat(2, 380px);
  `}
`;

export const VideoGrid = styled.div`
  grid-template-columns: 1fr;


  ${media.tabletViewportWidth`
    display: grid;
    align-items: center;
    grid-template-rows: 8fr;
    gap: 0rem 1.2rem;
    grid-template-columns: repeat(2, 340px);
  `}

  ${media.tabletLandscapeViewportWidth`
    gap: 1.2rem 1.2rem;
    grid-template-columns: repeat(3, 300px);
  `}

  ${media.desktopViewportWidth`
    grid-template-columns: repeat(3, 380px);
  `}
`;

export const PhotoGrid = styled.div`
  display: grid;
  align-items: center;
  grid-template-rows: 8fr;
  gap: 1.2rem 1.2rem;
  grid-template-columns: 1fr;

  ${media.tabletViewportWidth`
    grid-template-columns: repeat(2, 1fr);

    a {
      &:last-child {
        display: none;
      }
    }

    .item-1 {
      grid-row: 1 / span 2;
      grid-column: 1 / span 2;

      img {
        min-width: 100%;
      }
    }
  `}

  ${media.tabletLandscapeViewportWidth`
    grid-template-columns: repeat(3, 300px);

    a {
      &:last-child {
        display: block;
      }
    }
  `}

  ${media.desktopViewportWidth`
    grid-template-columns: repeat(3, 380px);
  `}
`;
