import logo from '../../../../assets/logos/masylogofont.png';
import { Container } from '../../../../components/common';
import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import NavbarLinks from '../NavbarLinks';
import { Wrapper } from './styles';

const Navbar = () => (
  <header>
    <Wrapper as={Container}>
      <div style={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}>
        <Link to="/">
          <img src={logo} alt="Maksymilian Grela" />
        </Link>

        <Link to="/en">
          <Language>English Version</Language>
        </Link>
      </div>

      <NavbarLinks desktop />
    </Wrapper>
  </header>
);

export default Navbar;

export const Language = styled.section`
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 5px;
  transition: 0.3s;
  font-weight: 700;

  &:hover {
    color: #cb2027;
  }
`;
