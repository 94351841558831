import maxrecfb from '../../../assets/logos/maxrec-fb.png';
import { contact, defaultDescriptionEN, defaultTitleEN, foundingDate, legalName, url } from '../../../data/config';
import React from 'react';
import Helmet from 'react-helmet';

export const SEOEN = ({ title = defaultTitleEN, description = defaultDescriptionEN, location = '' }) => {
  const structuredDataOrganization = `{ 
		"@context": "http://schema.org",
		"@type": "Organization",
		"legalName": "${legalName}",
		"url": "${url}",
		"foundingDate": "${foundingDate}",
		"founders": [{
			"@type": "Person",
			"name": "${legalName}"
		}],
		"contactPoint": [{
			"@type": "ContactPoint",
			"email": "${contact.email}",
			"telephone": "${contact.phone}",
		}],
  	}`;

  return (
    <Helmet>
      <meta name="description" content="Video and Photo Services" />
      <meta name="image" content={`${url}${maxrecfb}`} />
      <meta property="og:url" content={`${url}${location}`} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="MAXREC FILM STUDIO" />
      <meta property="og:description" content="Video and Photo Services" />
      <meta property="og:image" content={`${url}${maxrecfb}`} />
      <script type="application/ld+json">{structuredDataOrganization}</script>
      <title>{title}</title>
      <html lang="en" dir="ltr" />
    </Helmet>
  );
};
