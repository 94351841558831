import React from 'react';
import Hamburger from './Hamburger';
import NavbarEN from './NavbarEN';
import SidebarEN from './SidebarEN';
import { Overlay, Wrapper } from './styles';

export const HeaderEN = ({ transparent, sidebar, toggle }) => (
  <Wrapper transparent>
    <Overlay sidebar={sidebar} onClick={() => toggle(!sidebar)} />
    <NavbarEN />
    <Hamburger sidebar={sidebar} toggle={toggle} />
    <SidebarEN sidebar={sidebar} toggle={toggle} />
  </Wrapper>
);
