import { ComponentWrapper, Container, TextWrapper } from '../../../components/common';
import { H3 } from '../../../components/common/Typography';
import React from 'react';

export const AboutUs = () => (
  <ComponentWrapper large as={Container} id="about-us">
    <H3>O nas</H3>

    <TextWrapper left>
      <br />
      MAXREC to Studio Produkcji Filmów reklamowych, fabularnych, wizerunkowych, a także spotów reklamowych i
      teledysków.
      <br />
      <br />
      Realizujemy również sesje zdjęciowe.
      <br />
      <br />
      Rzetelność wykonywanych usług i satysfakcja klienta są naszymi priorytetami.
      <br />
      <br />
      <br />
      <p style={{ textAlign: 'center' }}>Zapraszamy do współpracy.</p>
    </TextWrapper>
  </ComponentWrapper>
);
