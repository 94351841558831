import React from 'react';
import NavbarLinksEN from '../NavbarLinksEN';
import { Wrapper } from './styles';

const SidebarEN = ({ sidebar, toggle }) => (
  <Wrapper active={sidebar}>
    <NavbarLinksEN sidebar={sidebar} toggle={toggle} />
  </Wrapper>
);

export default SidebarEN;
