import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { media } from '../../../../utils';
import { Bar, Wrapper } from './styles';

const Hamburger = ({ sidebar, toggle }) => (
  <>
    <Div>
      <Link to="/en">
        <Language>English</Language>
      </Link>
    </Div>

    <Wrapper sidebar={sidebar} onClick={() => toggle(!sidebar)}>
      <Bar top sidebar={sidebar} />
      <Bar mid sidebar={sidebar} />
      <Bar bottom sidebar={sidebar} />
    </Wrapper>
  </>
);

export default Hamburger;

export const Div = styled.div`
  ${media.tabletViewportWidth`
    display: none;
  `};

  position: absolute;
  right: 70px;
  top: 26px;
`;

export const Language = styled.section`
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  margin-left: 20px;

  transition: 0.3s;
  font-weight: 700;

  &:hover {
    color: #cb2027;
  }
`;
