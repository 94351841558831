import React from 'react';
import styled from 'styled-components';
import Hamburger from './Hamburger';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { Overlay, Wrapper } from './styles';

export const Header = ({ transparent, sidebar, toggle }) => (
  <Wrapper transparent>
    <Overlay sidebar={sidebar} onClick={() => toggle(!sidebar)} />
    <Navbar />

    <Hamburger sidebar={sidebar} toggle={toggle} />
    <Sidebar sidebar={sidebar} toggle={toggle} />
  </Wrapper>
);

export const Language = styled.section`
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  margin-left: 20px;

  transition: 0.3s;
  font-weight: 700;

  &:hover {
    color: #cb2027;
  }
`;
