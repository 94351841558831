import logo from '../../../assets/logos/max_rec_white_logonew.png';
import showreel from '../../../assets/SHOWREEL_BLACK.mp4';

import { AButton, Button, Container } from '../../../components/common';
import { Header } from '../../../components/theme';
import React, { useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Parallax } from 'react-scroll-parallax';
import { Center, IntroWrapper, Thumbnail, Wrapper } from './styles';

export const Intro = () => {
  const [sidebar, toggle] = useState(false);

  return (
    <Wrapper sidebar={sidebar}>
      <Header sidebar={sidebar} toggle={toggle} />
      <IntroWrapper as={Container}>
        <Center>
          <video id="background-video" autoPlay loop muted>
            <source src={showreel} type="video/mp4" />
          </video>



          <Parallax y={[-20, 20]} tagOuter="figure" styles={{'paddingTop': '50vh'}}>
            <Thumbnail>
              <img src={logo} alt="MAXREC Maksymilian Grela" />
            </Thumbnail>

            <AButton transparent href="https://www.youtube.com/watch?v=eC49LGBNg6c" target="_blank">

                Zobacz Showreel

            </AButton>


          </Parallax>
        </Center>

        {/* <Bottom>
          <Links>
            {social.map(({ id, name, link, icon }) => (
              <a key={id} href={link} target="_blank" rel="noopener noreferrer">
                <img width="35" src={icon} alt={name} />
              </a>
            ))}
          </Links>
        </Bottom> */}
      </IntroWrapper>
    </Wrapper>
  );
};
