import { ComponentWrapper, Container, GridTest, H3, VideoWrapper } from '../../../components/common';
import React from 'react';
import ReactPlayer from 'react-player';

export const MusicVideos = () => (
  <ComponentWrapper as={Container} id="music-videos">
    <H3>Teledysk</H3>

    <VideoWrapper>
      <GridTest>
        <div>
          <ReactPlayer width="100%" height="210px" url="https://youtu.be/mPFj8piWs20" controls />
        </div>

        <div>
          <ReactPlayer width="100%" height="210px" url="https://youtu.be/Xft8HuQHhgg" controls />
        </div>

        <div>
          <ReactPlayer width="100%" height="210px" url="https://youtu.be/KsZhd4claqc" controls />
        </div>

        <div>
          <ReactPlayer width="100%" height="210px" url="https://www.youtube.com/watch?v=mSA2DTR_kF0" controls />
        </div>

        <div>
          <ReactPlayer width="100%" height="210px" url="https://www.youtube.com/watch?v=HKGxhsnNswo" controls />
        </div>

        <div>
          <ReactPlayer width="100%" height="210px" url="https://youtu.be/FJTBkS7xvGE" controls />
        </div>


      </GridTest>
    </VideoWrapper>
  </ComponentWrapper>
);
